import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.subscriptionStatus < 3)?_c(VBtn,_vm._g(_vm._b({staticClass:"btn four-eyes-button",attrs:{"rounded":"","large":"","depressed":"","outlined":"","color":"#003D6D"}},'v-btn',attrs,false),on),[_vm._v(" Four eyes law ")]):_vm._e()]}}]),model:{value:(_vm.dialogFourEyes),callback:function ($$v) {_vm.dialogFourEyes=$$v},expression:"dialogFourEyes"}},[_c(VCard,{staticClass:"pb-3",attrs:{"id":"card-eye"}},[_c(VCardActions,{staticClass:"px-3 pb-3"},[_c(VFlex,{attrs:{"text-xs-right":""}},[_c(VBtn,{attrs:{"color":"orange","dark":"","small":"","absolute":"","fab":"","right":""},on:{"click":function($event){_vm.dialogFourEyes = false}}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)],1)],1),_c(VCardTitle,{staticClass:"font-weight-bold text-h5"},[_vm._v("Four eyes law")]),_c(VDivider,{attrs:{"id":"divisor"}}),_c('div',{staticClass:"mb-8",attrs:{"id":"border-blue"}},[_c(VCardText,{staticClass:"font-weight-bold text-h6 blue-text"},[_vm._v("Following the company's policies select the underwriter who will check the quotation.")]),_c(VDivider,{attrs:{"id":"divisor"}}),_c(VAutocomplete,{staticClass:"rounded-lg inputSearch mx-6 my-3",attrs:{"filled":"","background-color":"#EDF2F8","label":"Search","prepend-inner-icon":"mdi-magnify","items":_vm.underwriters,"item-text":_vm.getFullName,"item-value":"id"},model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}}),_c(VBtn,{staticClass:"d-flex mx-auto px-16 btnRequest",attrs:{"elevation":"2","large":"","rounded":""},on:{"click":function($event){return _vm.addUnderwriters()}}},[_vm._v("Request")]),_c(VCardText,{staticClass:"font-weight-bold text-h6 blue-text"},[_vm._v("This is your review history")]),_c(VDivider,{attrs:{"id":"divisor"}}),[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.underwritersTable,"items-per-page":5},scopedSlots:_vm._u([{key:"item.reviewer_user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.reviewer_user.name)+" ")]}}])})]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }