import { render, staticRenderFns } from "./ButtonsQuotation.vue?vue&type=template&id=03ecfb54&scoped=true&"
import script from "./ButtonsQuotation.vue?vue&type=script&lang=js&"
export * from "./ButtonsQuotation.vue?vue&type=script&lang=js&"
import style0 from "./ButtonsQuotation.vue?vue&type=style&index=0&id=03ecfb54&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03ecfb54",
  null
  
)

export default component.exports