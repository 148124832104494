import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n    Cont\n    d-flex\n    justify-start\n    align-center\n    flex-wrap\n    align-content-center\n    mt-6\n  "},[_vm._m(0),_c('div',{staticClass:"Table d-flex justify-end align-content-start flex-wrap"},[_vm._m(1),_c('div',{staticClass:"Line"},[_c('div',{staticClass:"MiniRow Txt"},[_vm._v("Months")]),_c('div',{staticClass:"Row"},[_c(VTextField,{on:{"input":function($event){return _vm.$v.alopRates.month.$touch()},"blur":function($event){_vm.$v.alopRates.month.$touch();
            _vm.checkField('month');}},model:{value:(_vm.$v.alopRates.month.$model),callback:function ($$v) {_vm.$set(_vm.$v.alopRates.month, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.alopRates.month.$model"}})],1),_c('div',{staticClass:"Row"},[_c(VSelect,{attrs:{"items":_vm.ALOP,"item-text":"data","item-value":"id"},on:{"input":function($event){return _vm.$v.alopRates.alopSelect.$touch()},"blur":function($event){_vm.$v.alopRates.alopSelect.$touch();
            _vm.checkField('alopSelect');},"change":function($event){return _vm.changeFactor(_vm.$v.alopRates.alopSelect.$model)}},model:{value:(_vm.$v.alopRates.alopSelect.$model),callback:function ($$v) {_vm.$set(_vm.$v.alopRates.alopSelect, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.alopRates.alopSelect.$model"}})],1),_c('div',{staticClass:"Row"},[_c(VTextField,{attrs:{"type":"number"},on:{"input":function($event){return _vm.$v.alopRates.factorAlop.$touch()},"blur":function($event){_vm.$v.alopRates.factorAlop.$touch();
            _vm.checkField('factorAlop');}},model:{value:(_vm.$v.alopRates.factorAlop.$model),callback:function ($$v) {_vm.$set(_vm.$v.alopRates.factorAlop, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.alopRates.factorAlop.$model"}})],1),_c('div',{staticClass:"Row"},[_c(VTextField,{attrs:{"type":"number"},on:{"input":function($event){_vm.SET_ALOP('credit', this), _vm.calculateFinal()},"blur":function($event){_vm.SET_ALOP('credit', this);
            _vm.checkField('floodRate');}},model:{value:(_vm.$v.alopRates.floodRate.$model),callback:function ($$v) {_vm.$set(_vm.$v.alopRates.floodRate, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.alopRates.floodRate.$model"}})],1),_c('div',{staticClass:"Row"},[_c(VTextField,{attrs:{"type":"number"},on:{"input":function($event){_vm.SET_ALOP('credit', this);
            _vm.calculateFinal();},"blur":function($event){_vm.SET_ALOP('credit', this);
            _vm.checkField('credit');}},model:{value:(_vm.$v.alopRates.credit.$model),callback:function ($$v) {_vm.$set(_vm.$v.alopRates.credit, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.alopRates.credit.$model"}})],1),_c('div',{staticClass:"Row"},[_c(VSelect,{attrs:{"items":_vm.reasons,"item-text":"data","item-value":"id"},on:{"input":function($event){return _vm.$v.alopRates.reasonsSelect.$touch()},"blur":function($event){_vm.$v.alopRates.reasonsSelect.$touch();
            _vm.checkField('reasonsSelect');}},model:{value:(_vm.$v.alopRates.reasonsSelect.$model),callback:function ($$v) {_vm.$set(_vm.$v.alopRates.reasonsSelect, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.alopRates.reasonsSelect.$model"}})],1),_c('div',{staticClass:"Row"},[_c(VTextField,{model:{value:(_vm.alopRate.finalRate),callback:function ($$v) {_vm.$set(_vm.alopRate, "finalRate", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"alopRate.finalRate"}})],1)]),_c('div',{staticClass:"ExpansionLineTop mt-3 HideOnMovil"})])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"TitleCont"},[_c('h5',[_vm._v("ALOP Rate")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Line"},[_c('div',{staticClass:"MiniRow Bold"}),_c('div',{staticClass:"Row Bold"}),_c('div',{staticClass:"Row Bold"}),_c('div',{staticClass:"Row Bold"},[_vm._v("Factor")]),_c('div',{staticClass:"Row Bold"},[_vm._v("Rate")]),_c('div',{staticClass:"Row Bold"},[_vm._v(" Credit / "),_c('br'),_vm._v(" Surcharge ")]),_c('div',{staticClass:"Row Bold"},[_vm._v("Reason")]),_c('div',{staticClass:"Row Bold"},[_vm._v("Final Rate")])])}]

export { render, staticRenderFns }