import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Cont d-flex justify-start align-center flex-wrap align-content-center mt-6"},[_vm._m(0),_c('div',{staticClass:"Table d-flex justify-end align-content-start flex-wrap"},[_vm._m(1),_vm._l((_vm.$v.computedGroup.$each.$iter),function(v,index){return _c('div',{key:index,staticClass:"Line"},[_c('div',{staticClass:"Row"},[_c(VTextField,{attrs:{"readonly":""},on:{"blur":function($event){v.activity.$touch();
            _vm.checkField(index, 'activity');}},model:{value:(v.activity.$model),callback:function ($$v) {_vm.$set(v.activity, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"v.activity.$model"}})],1),_c('div',{staticClass:"Row"},[_c(VTextField,{attrs:{"readonly":""},on:{"blur":function($event){v.activityRate.$touch();
            _vm.checkField(index, 'activityRate');}},model:{value:(v.activityRate.$model),callback:function ($$v) {_vm.$set(v.activityRate, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"v.activityRate.$model"}})],1),_c('div',{staticClass:"Row"},[_c(VTextField,{attrs:{"readonly":""},on:{"blur":function($event){v.rate.$touch();
            _vm.checkField(index, 'rate');}},model:{value:(v.rate.$model),callback:function ($$v) {_vm.$set(v.rate, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"v.rate.$model"}})],1),_c('div',{staticClass:"Row"},[_c(VTextField,{attrs:{"suffix":"%","type":"number"},on:{"blur":function($event){v.credit.$touch();
            _vm.checkField(index, 'credit');}},model:{value:(v.credit.$model),callback:function ($$v) {_vm.$set(v.credit, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"v.credit.$model"}})],1),_c('div',{staticClass:"Row"},[_c(VSelect,{attrs:{"items":_vm.reasons,"item-value":"id","item-text":"description","placeholder":"Select a Reason"},on:{"blur":function($event){v.reason.$touch();
            _vm.checkField(index, 'reason');}},model:{value:(v.reason.$model),callback:function ($$v) {_vm.$set(v.reason, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"v.reason.$model"}})],1),_c('div',{staticClass:"Row"},[_c(VTextField,{attrs:{"readonly":""},on:{"blur":function($event){v.finalRate.$touch();
            _vm.checkField(index, 'finalRate');}},model:{value:(v.finalRate.$model),callback:function ($$v) {_vm.$set(v.finalRate, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"v.finalRate.$model"}})],1),_c('div',{staticClass:"Row"},[_c(VTextField,{attrs:{"readonly":""},on:{"blur":function($event){v.premiumRate.$touch();
            _vm.checkField(index, 'premiumRate');}},model:{value:(v.premiumRate.$model),callback:function ($$v) {_vm.$set(v.premiumRate, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"v.premiumRate.$model"}})],1)])}),_c('div',{staticClass:"Line ButtonLine"},[_c(VBtn,{staticClass:"btn",attrs:{"rounded":"","text":"","color":"#003D6D"},on:{"click":function($event){return _vm.addFields()}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(" mdi-plus-circle ")]),_vm._v(" Add New ")],1)],1),_c('div',{staticClass:"ExpansionLineTop mt-3 HideOnMovil"})],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"TitleCont"},[_c('h5',[_vm._v("Non CAT Rates")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Line"},[_c('div',{staticClass:"Row Bold"},[_vm._v("Activity")]),_c('div',{staticClass:"Row Bold"}),_c('div',{staticClass:"Row Bold"},[_vm._v("Rate")]),_c('div',{staticClass:"Row Bold"},[_vm._v("Credit / Surchage")]),_c('div',{staticClass:"Row Bold"},[_vm._v("Reason")]),_c('div',{staticClass:"Row Bold"},[_vm._v("Final Rate")]),_c('div',{staticClass:"Row Bold"},[_vm._v("Premium")])])}]

export { render, staticRenderFns }