import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ExpandContent",attrs:{"disabled":_vm.loadingPanel}},[_c(VForm,{staticClass:"Form d-flex justify-start align-center flex-wrap"},[_c('div',{staticClass:"InputContent"},[_c(VSelect,{attrs:{"required":"","label":"Currency","items":_vm.filteredCurrencies,"item-text":"key","item-value":"id","hint":((_vm.currencyTarifador.region || 'Region') + ", " + (_vm.currencyTarifador.description || 'Description')),"persistent-hint":"","return-object":"","loading":_vm.loadingCurrencies,"disabled":_vm.currencies.length === 0},on:{"change":function($event){return _vm.changeTiv()}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.key)+" - "+_vm._s(data.item.description)+" ")]}}]),model:{value:(_vm.currencyTarifador),callback:function ($$v) {_vm.currencyTarifador=$$v},expression:"currencyTarifador"}})],1),_c('div',{staticClass:"InputContent"},[_c(VTextField,{attrs:{"label":"TIV","readonly":""},model:{value:(_vm.quotation.tivTarifadorSelect),callback:function ($$v) {_vm.$set(_vm.quotation, "tivTarifadorSelect", $$v)},expression:"quotation.tivTarifadorSelect"}})],1),_c('div',{staticClass:"InputContent"},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Inception Date","readonly":""},model:{value:(_vm.quotation.inceptionDate),callback:function ($$v) {_vm.$set(_vm.quotation, "inceptionDate", $$v)},expression:"quotation.inceptionDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c(VDatePicker,{attrs:{"disabled":"","readonly":""},on:{"input":function($event){(_vm.menu2 = false), _vm.setStateQuotation('inceptionDate', this)}},model:{value:(_vm.quotation.inceptionDate),callback:function ($$v) {_vm.$set(_vm.quotation, "inceptionDate", $$v)},expression:"quotation.inceptionDate"}})],1)],1),_c('div',{staticClass:"InputContent"},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Expiry Date","readonly":""},model:{value:(_vm.quotation.expiryDate),callback:function ($$v) {_vm.$set(_vm.quotation, "expiryDate", $$v)},expression:"quotation.expiryDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VDatePicker,{attrs:{"disabled":"","readonly":""},on:{"input":function($event){(_vm.menu = false), _vm.setStateQuotation('expiryDate', this)}},model:{value:(_vm.quotation.expiryDate),callback:function ($$v) {_vm.$set(_vm.quotation, "expiryDate", $$v)},expression:"quotation.expiryDate"}})],1)],1),_c('div',{staticClass:"InputContent"},[_c(VTextField,{attrs:{"label":"Term","readonly":""},model:{value:(_vm.quotation.term),callback:function ($$v) {_vm.$set(_vm.quotation, "term", $$v)},expression:"quotation.term"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }