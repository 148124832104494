import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"persistent":_vm.persistent,"max-width":"390"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","text":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"#9D9B9B"}},[_vm._v(" mdi-close-circle-outline ")])],1)]}}]),model:{value:(_vm.displayConfirmationModal),callback:function ($$v) {_vm.displayConfirmationModal=$$v},expression:"displayConfirmationModal"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VCardText,[_vm._v(_vm._s(_vm.description))]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"Btn",attrs:{"rounded":"","color":_vm.disagreeColorText,"text":""},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" "+_vm._s(_vm.disagreeText)+" ")]),_c(VBtn,{staticClass:"Btn",attrs:{"depressed":"","rounded":"","color":_vm.acceptColorText,"dark":""},on:{"click":function($event){return _vm.acceptModal()}}},[_vm._v(" "+_vm._s(_vm.acceptText)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }